<template>
    <div class="">
        <MainBannerSection />
        
        <div class="container-fluid">
            
            <div class="mt-4">
                <h3 class="text-center text-black m-0">SHOP BY CATEGORY</h3>
            </div>

            <div class="mx-0 mx-lg-5 mx-md-5 my-4">
                <CategoriesSection :categories="categoryStore.parentCategories" />
            </div>

            <div class="my-2">
                <h3 class="text-center text-black m-0">OUR BEST SELLING PRODUCTS</h3>
            </div>

            <div class="mx-0 mx-lg-5 mx-md-5 my-4">

                <div class="row">
                    <div class="col-6 col-lg-3 col-md-4 col-sm-6 p-1" v-for="(item, i) in OurProductImages" :key="i">
                        <OurProductCard :item="item"/>
                    </div>
                </div>

            </div>

            <div class="my-2">
                <h3 class="text-center text-black m-0">OUR SERVICES</h3>
                <p class="text-center">THERE ARE PEOPLE WHO DECORATE AND THOSE WHO DESIGN</p>
            </div>

            <div class="mx-0 mx-lg-5 mx-md-5 mt-4">

                <BodyBannerSection />

            </div>

            <div class="my-4">
                <h2 class="text-center text-black m-0">AR EXPERIENCE CENTER</h2>
            </div>

        </div>
        <div class="container-fluid bg-footer">
        <div class="d-flex justify-content-center flex-wrap px-0 px-lg-5 px-md-5 pt-3">
            <div class="px-2">
                <p class="d-flex justify-content-center fs-3 text-default fw-lighter">INSPIRATION, DELIVERED</p>
                <p class="footer-line d-flex justify-content-center text-default pb-3 fs-7">
                    Discover our products, places, services and space
                </p>

                <div class="d-flex w-100 my-0 py-1 border border-black position-relative">
                    <div class="input-group">
                        <input type="text" v-model="subscriptionEmail" class="form-control bg-white border-0 input-container bg-footer" id="gsearch" name="gsearch"
                            placeholder="Enter your mail" aria-label="Enter your mail" aria-describedby="basic-addon2">
                        <button 
                            class="input-group-text bg-white border-0 fw-bold fs-7 bg-footer" 
                            id="basic-addon2" 
                            @click.prevent="emailSubscription"
                        >
                            SUBSCRIBE
                        </button>
                    </div>
                </div>
               <small class="text-danger m-0">
                    {{ errorMessage.email }}
               </small> 
            </div>
        </div>
        </div>
    </div>
    <CommonsToaster ref="toaster"/>
</template>

<script setup>
import MainBannerSection from "@/views/index-view/MainBannerSection.vue";
import CategoriesSection from "@/views/index-view/CategoriesSection.vue";

import { itemIndicesClient, itemsV3Client, categoriesClient } from "@/assets/apis/config";
import { useCategoryStore } from "@/stores/category-store";
import OurProductCard from "~/views/index-view/OurProductCard.vue";
import BodyBannerSection from "~/views/index-view/BodyBannerSection.vue";
import { authClient } from "@/assets/apis/config";
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css'; // for React, Vue and Svelte
// import { useNotyf } from '@/composables/useNotyf';

// Create an instance of Notyf
const notyf = process.client? new Notyf(): null;

const { $gtag } = useNuxtApp();
const page = ref(1);
const size = ref(20);
const categoryStore = useCategoryStore();
const toaster = ref(null);
const subscriptionEmail = ref("");
const errorMessage = ref({
    email: "",
});
const OurProductImages = ref([
    {
        attachments: [{url:"/images/index/5.png"}],
        title: "",
    },
    {
        attachments: [{url:"/images/index/6.png"}],
        title: "",
    },
    {
        attachments: [{url:"/images/index/7.png"}],
        title: "",
    },
    {
        attachments: [{url:"/images/index/8.png"}],
        title: "",
    },
    {
        attachments: [{url:"/images/index/9.png"}],
        title: "",
    },
    {
        attachments: [{url:"/images/index/10.png"}],
        title: "",
    },
    {
        attachments: [{url:"/images/index/11.png"}],
        title: "",
    },
    {
        attachments: [{url:"/images/index/12.png"}],
        title: "",
    },
])

const fetchSignatureItems = async () => {
  let params = {
        filters: [
            {
                field: 'collectionIds',
                values: ['1169215093431209984'],
            }
        ],
        query: "",
        page: 1,
        size: 8,
    }
    let res = await itemIndicesClient.search(params)
    
        if (res.status == 200 && !res.networkError) {
            if (res.body && res.body.list && res.body.list.length) {
                OurProductImages.value = res.body.list.length > 8 ? res.body.list.slice(0, 8) : res.body.list;
            }
        }
};

const onSuccess = async () => {
    await clearValidations();
    subscriptionEmail.value = "";
};

const clearValidations = async () => {
    Object.keys(errorMessage.value).forEach((key) => {
        errorMessage.value[key] = "";
    });
};

const validate = async () => {
    clearValidations();
    if (subscriptionEmail.value.trim().length == 0) {
        errorMessage.value.email = "Please Provide Your Email";
        return false;
    }

    if (!subscriptionEmail.value.match(/\S+@\S+\.\S+/)) {
        errorMessage.value.email = "Please Provide a Correct Email";
        return false;
    }
    return true;
};

const emailSubscription = async () => {
    if (await validate()) {
        let data = {
            userId: "",
            email: subscriptionEmail.value,
        };
        authClient.emailSubscription(data).then((res) => {
            if (res.status == 201 && !res.networkError) {
                notyf.success('Subscription Successful');
                onSuccess();
            } else if (res.status == 409 && !res.networkError) {
                notyf.error('Failed!! Already Subscribed with this email');
                errorMessage.value.email = "Already Subscribed with this email";
            } else {
                notyf.error('Failed to Subscribe');
            }
        });
    }
};


onMounted(async () => {
    await fetchSignatureItems();
    if (process.client) {
        $gtag('event', 'home_page', {
            'page_type': "Home Page",
            'page_name': "Home",
        });
    }
});
</script>

<template>
    <div class="border border-2 border-dark p-2 w-100 cursor-pointer our-product-card" @click.prevent="goToDetail">
        <div class="bg-img position-relative" :style="{ backgroundImage: `url(${image})`}">
            <!-- <div class="our-product-title position-absolute bottom-0 w-100 justify-content-center align-content-center text-white fs-5 fw-bolder" style="z-index: 1000;">{{ item.title }}</div> -->
        </div>
    </div>
</template>

<script setup>

const props = defineProps({
    item: {
        type: Object,
    }
})

const router = useRouter();

const image = computed(() => {
    if (props.item?.attachments?.length) {
        return props.item?.attachments[0]?.url
    }
    return "";
})

const goToDetail = () => {
    router.push({
        name: "details",
        query: { id: props.item.id, sourceType: "item" },
    });
};
</script>

<style lang="scss" scoped>
.bg-img {
    width: 100%;
    height: 175px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.our-product-card {
    .our-product-title {
        display: none !important;
    }
    &:hover {
        .our-product-title {
            display: flex !important;
        }
        background-color: rgba(0,0,0,0.1);
    }
}
</style>
<template>
    <div class="" v-if="childCategories.length > 0">
        <carousel
            style="width: 100%"
            ref="myCarousel"
            :breakpoints="breakpoints"
            :settings="settings"
            class="product_slider"
            :wrap-around="true"
        >
            <template #slides>
                <slide v-for="(category, j) in childCategories" :key="j">
                    <ChildrenCategoryItem
                        class="carousel__item"
                        :category="category"
                    />
                </slide>
            </template>

            <template #addons>
                <Navigation />
            </template>
        </carousel>
    </div>
</template>

<script setup>
import { ref, toRef, computed } from "vue";
import ChildrenCategoryItem from "./items/ChildrenCategoryItem.vue";

import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";

const props = defineProps({
    categories: {
        type: Array,
        default: () => [],
    },
});

const settings = ref({
    itemsToShow: 5,
    snapAlign: "start",
});

const breakpoints = ref({
    0: {
        itemsToShow: 1,
        snapAlign: "start",
    },
    350: {
        itemsToShow: 1.5,
        snapAlign: "center",
    },
    650: {
        itemsToShow: 2.5,
        snapAlign: "center",
    },
    1024: {
        itemsToShow: 3,
        snapAlign: "start",
    },
    1400: {
        itemsToShow: 5,
        snapAlign: "start",
    },
});

const myCarousel = ref(null);

const categoriesRef = toRef(props, "categories");

const childCategories = computed(() => {
    let children = [];

    if (categoriesRef.value && categoriesRef.value.length > 0) {
        if (
            categoriesRef.value[0].children &&
            categoriesRef.value[0].children.length > 0
        ) {
            return categoriesRef.value[0].children;
        } else {
            return categoriesRef.value;
        }
    }

    return children;
});
</script>

<style lang="scss">
.product_slider {
    .carousel__slide {}

    .carousel__prev,
    .carousel__next {
        width: 40px !important;
        height: 40px !important;
    }

    .carousel__prev {
        margin-left: -2%;
    }

    .carousel__next {
        margin-right: -2%;
    }

    .carousel__prev,
    .carousel__next {
        font-size: 2rem;
        color: #C3B090
    }

    @media screen and (max-width: 1023px) {
        
    .carousel__track {
  transform-style: preserve-3d;
}
    .carousel__slide--sliding {
  transition: 0.5s;
}

.carousel__slide {
  opacity: 0.9;
  transform: rotateY(-20deg) scale(0.9);
}

.carousel__slide--active ~ .carousel__slide {
  transform: rotateY(20deg) scale(0.9);
}

.carousel__slide--prev {
  opacity: 1;
  transform: rotateY(-10deg) scale(0.95);
}

.carousel__slide--next {
  opacity: 1;
  transform: rotateY(10deg) scale(0.95);
}

.carousel__slide--active {
  opacity: 1;
  transform: rotateY(0) scale(1.1);
}
}

}
</style>

<template>
    <div class="cursor-pointer w-100 mx-2" ref="categoryContainer" v-if="categoryRef.isLoading" @click.prevent="goToDetailPage()">
        <img class="w-100" :src="image" alt="">
        <p class="category-type"> {{ name }}></p>
    </div>

    <div class="cursor-pointer w-100 mx-2 h-100" ref="categoryContainer" v-else @click.prevent="goToDetailPage()">
        <div class="w-100 h-100">
            <div class="category-item-img bg-img-cover w-100 h-100 bg-white" :style="{ backgroundImage: `url(${image})`, height: (Number(getHight)/1.11)+'px' }"></div>
        </div>
        <!-- <p class="fs-6 text-start" @click.prevent="onGotoDetailePage"> {{ name }}</p> -->
        <p class="fs-6 text-start ps-0 ps-lg-4 ps-md-2 pt-2"> {{ name }}</p>
    </div>
</template>

<script setup>
const router = useRouter();
const props = defineProps({
    category: {
        type: Object,
        default: null,
    }
});

const categoryRef = toRef(props, 'category');
const categoryContainer = ref(null);

const name = computed(() => {
    
    if (categoryRef.value && categoryRef.value.name && categoryRef.value.name.length) {
        return categoryRef.value.name;
    }

    return '';
});

const image = computed(() => {
    let img = "/images/no-image.png";
    if (categoryRef.value?.extras) {
        if (categoryRef.value.extras?.thumbnail_image_url?.length) {
            img = categoryRef.value.extras.thumbnail_image_url;
        } else if (categoryRef.value.extras.banner_image1_url?.length) {
            img = categoryRef.value.extras.banner_image1_url;
        } 
    } else if (categoryRef.value?.url)  img = categoryRef.value.url;
    return img;
});

const getHight = computed(()=> {
    if (categoryContainer.value?.offsetWidth) {
        return categoryContainer.value.offsetWidth < 600 ? categoryContainer.value.offsetWidth : 500;
        // return categoryContainer.value.offsetWidth;
    }
    return 0
})

const goToDetailPage = async () => {
    let obj = {
        categoryId: categoryRef.value && categoryRef.value.id ? categoryRef.value.id : '', 
        categoryName: name.value,
        categoryBanner: image.value,
    }
    router.push({
        name: "shop",
        query: obj,
    });
};
</script>

<style lang="scss">
.category-item-img {
    min-height: 300px;
}

@media screen and (max-width: 1023px){
    .category-item-img {
        min-height: 185px;
    }
}
</style>
<template>
    <div>
        <div v-if="isLoading || !bannerData.length">
            <Skeletor
                class="label main-banner" style="margin-bottom: 0.5rem; width: 100%; border-radius: 0;"
            />
        </div>

        <carousel
            style="width: 100%;"
            ref="myCarousel"
            class="main-banner"
            :settings="settings"
            :wrap-around="true"
            v-else
		>

			<template #slides>
				<slide 
                    v-for="(banner, j) in bannerData" 
                    :key="j" 
                    class="w-100 h-100 bg-img-cover"
                    :style="{'background-image': `url(${banner.url})`}"
                >
				</slide>
			</template>

			<template #addons>
				<!-- <Navigation /> -->
                <Pagination v-if="bannerData?.length > 1"/>
			</template>

        </carousel>
    </div>
</template>

<script setup>
import { ref, toRef, computed, onMounted } from "vue";

import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import { cmsClient } from "@/assets/apis/config";
import { Skeletor } from "vue-skeletor";
import "vue-skeletor/dist/vue-skeletor.css";

const isLoading = ref(false);

const settings = ref({
	itemsToShow: 1,
	snapAlign: "center",
    autoplay: 3500,
    loop: true
});

const myCarousel = ref(null);
const bannerData = ref([
    {
        url : "/images/index/1.png"
    }
]);

const fetchSliderImages = async () => {
    isLoading.value = true;
    let res = await cmsClient.fetchSliderImages({size: 10})
    
    if (res.status == 200 && !res.networkError) {
        if (res.body && res.body.list && res.body.list.length > 0) {
            let list = res.body.list;
            bannerData.value = list.sort((x,y)=> x.sortOrder - y.sortOrder);
            isLoading.value = false;
        }
    }
}

onMounted(async () => {
  await fetchSliderImages();
});
</script>
<style lang="scss">
.carousel__viewport {
    height: 100% !important;
    .carousel__track {
        height: 100% !important;
        
    }
}
</style>
<style lang="scss" scoped>
.carousel__pagination {
    width: 100%;
    margin: 0 !important;
    position: absolute !important;
    bottom: 3% !important;
}
.carousel__viewport {
    height: 100% !important;
    .carousel__track {
        height: 100% !important;
        
    }
}

.main-banner {
    height: 70vh !important;
}

.carousel__pagination-button {
  display: block;
  border: 0;
  margin: 0;
  cursor: pointer;
  padding: var(--vc-pgn-margin);
  background: red;
  color: #F69320;
}

@media screen and (max-width: 700px){
    .carousel__pagination {
        left: -6% !important;
    }
    .main-banner {
        height: 30vh !important;
    }
    // .bg-img-cover{
    //     background-size: contain;
    // }
}
</style>
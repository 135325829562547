<template>
    <div class="position-relative">
        <div v-if="isLoading || !bannerData.length">
            {{ !bannerData.length }}
            <Skeletor
                class="label main-banner" style="margin-bottom: 0.5rem; width: 100%; border-radius: 0;"
            />
        </div>
        <carousel
            style="width: 100%;"
            ref="myCarousel"
            :settings="settings"
            :wrap-around="true"
            v-else
		>

			<template #slides>
				<slide 
                    v-for="(banner, j) in bannerData" 
                    :key="j" 
                    class="w-100 bg-img-cover main-banner"
                    :style="{'background-image': `url(${banner.url})`}"
                >
				</slide>
			</template>

			<template #addons>
				<!-- <Navigation /> -->
                <Pagination v-if="bannerData?.length > 1"/>
			</template>

        </carousel>
        <div class="position-absolute w-100 d-flex justify-content-center text-decoration-underline text-white" style="bottom: 5%;">
            <small class="fw-bold fs-7">REQUEST A DESIGN CONSULTATION</small>
        </div>
    </div>
</template>

<script setup>
import { ref, toRef, computed, onMounted } from "vue";

import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import { cmsClient } from "@/assets/apis/config";
import { Skeletor } from "vue-skeletor";
import "vue-skeletor/dist/vue-skeletor.css";


const isLoading = ref(false);

const settings = ref({
	itemsToShow: 1,
	snapAlign: "center",
    autoplay: 3500,
    loop: true
});

const myCarousel = ref(null);
const bannerData = ref([
    {
        url: "/images/index/13.png",
    },
    {
        url: "/images/index/14.jpg",
    },
]);

// const fetchSliderImages = async () => {
//     isLoading.value = true;
//     cmsClient.fetchSliderImages({size: 10}).then((res)=> {
//         if (res.status == 200 && !res.networkError) {
//             if (res.body && res.body.list && res.body.list.length > 0) {
//                 let list = res.body.list;
//                 bannerData.value = list.sort((x,y)=> x.sortOrder - y.sortOrder);
//                 isLoading.value = false;
//             }
//         }
//     })
// }

// onMounted(async () => {
//   await fetchSliderImages();
// });
</script>

<style lang="scss" scoped>
.carousel__pagination {
    width: 100%;
    margin: 0 !important;
    position: absolute !important;
    bottom: 3% !important;
}
@media screen and (max-width: 700px){
    .carousel__pagination {
        left: -6% !important;
    }
}

.main-banner {
    height: 70vh !important;
}

.carousel__pagination-button {
  display: block;
  border: 0;
  margin: 0;
  cursor: pointer;
  padding: var(--vc-pgn-margin);
  background: red;
  color: #F69320;
}

@media screen and (max-width: 700px){
    .carousel__pagination {
        left: -6% !important;
    }
    .main-banner {
        height: 40vh !important;
    }
}
</style>